<template>
    <Dialog :open="open" class="relative z-10">
      <div class="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" />

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div class="text-center mb-6">
              <img src="@/assets/logo.svg" alt="ROAM" class="mx-auto"/>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">One Moment Please...</DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500">We're ensuring everything's perfect for you. The page will be ready in a jiffy.</p>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'

const open = ref(true)
</script>

<script>
export default {
  mounted() {
    window.addEventListener('message', this.handleMessage);
  },

  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },

  methods: {
    handleMessage(event) {
      if (event.data.component === 'login') {
        this.$router.push('/login');
      } else if (event.data.component === 'otp') {
        this.$router.push('/admin/access');
      }

      return;
    }
  }
};
</script>